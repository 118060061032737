/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import styles from './CreateQuiz.module.css'
import ClearIcon from '@mui/icons-material/Clear';
import Modal from '@mui/material/Modal';
import { Button, createTheme, Fab, TextField, ThemeProvider, Tooltip } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import NewQuestion from './NewQuestion';

const CreateQuiz = ({open, setOpen}) => {
	const { course_id: courseId } = useParams()
	const [quizTitle, setQuizTitle] = useState('');
	const [quizSynopsis, setQuizSynopsis] = useState('');
	const [showAddQuestionForm, setShowAddQuestionForm] = useState(false);
	const [editQuestionIndex, setEditQuestionIndex] = useState(-1);
	const [questions, setQuestions] = useState([])
	const [timeLimit, setTimeLimit] = useState(dayjs('2022-04-17T01:00'))
	const [draggingQuestion, setDraggingQuestion] = useState(null)
	const [currentQuestion, setCurrentQuestion] = useState({
		type: "MCQ",
		statement: "",
		questionImages: [],
		options: [{
			id: 1,
			optionStatement: 'Option'
		}],
		correctAnswers: [],
		explanation: "",
		points: "1"
	})
	const [isValidTitle, setIsValidTitle] = useState(true);
	const [isValidTimeLimit, setIsValidTimeLimit] = useState(true);
	const [isValidQuestions, setIsValidQuestions] = useState(true);

	const validateQuiz = () => {
		var isValid = true;

		// title cannot be empty
		if (quizTitle === null || quizTitle.trim() === '') {
			isValid = false;
			setIsValidTitle(false);
			document.getElementById('quiz-title').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
		}

		// timeLimit cannot be 00:00:00
		if (!timeLimit.hour() && !timeLimit.minute() && !timeLimit.second()) {
			isValid = false;
			setIsValidTimeLimit(false);
		}

		// questions array cannot be empty
		if (!questions.length) {
			isValid = false;
			setIsValidQuestions(false);
		}

		return isValid;
	}

	const handleSubmit = async (isPublished) => {
		if (!validateQuiz()) {
			return;
		}

		const quiz = {
			isPublished: isPublished,
			courseId: courseId,
			timeLimit: `${timeLimit.hour() > 9 ? timeLimit.hour() : '0' + timeLimit.hour()}:${timeLimit.minute() > 9 ? timeLimit.minute() : '0' + timeLimit.minute()}:${timeLimit.second() > 9 ? timeLimit.second() : '0' + timeLimit.second()}`,
			quizData: {
				quizTitle: quizTitle.trim(),
				quizSynopsis: quizSynopsis.trim(),
				questions: questions.map(((question, i) => ({
					id: i,
					question: question.statement.trim(),
					answerSelectionType: (question.type === "MCQ") ? ("multiple") : (
						(question.type === "TF") ? ("single") : ("fill in the blanks") 
					),
					answers: question.options, 
					correctAnswer: question.correctAnswers,
					point: question.points,
					explanation: question.explanation.trim()
				}))),
			}
		}

		const res = await axios({
			method: 'post',
			url: `${process.env.REACT_APP_BASE_URL}/quiz`,
			data: quiz,
			headers: { 'Content-Type': 'application/json' },
		})

		if (res.status === 200) {
			window.location.reload();
		}
	}

	const handleClose = () => {
		setCurrentQuestion({
			type: "MCQ",
			statement: "",
			questionImages: [],
			options: [{
				id: 1,
				optionStatement: 'Option'
			}],
			correctAnswers: [],
			explanation: "",
			points: "1"
		})
		setShowAddQuestionForm(false);
		setOpen(false);
	}

	const handleDragStart = (e, item) => {
        setDraggingQuestion(item);
        e.dataTransfer.setData('text/plain', '');
    };

    const handleDragEnd = () => {
        setDraggingQuestion(null);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, targetItem) => {
        if (!draggingQuestion) return;

        const currentIndex = questions.indexOf(draggingQuestion);
        const targetIndex = questions.indexOf(targetItem);

        if (currentIndex !== -1 && targetIndex !== -1) {
            
			setQuestions((prevQuestions) => {
				const newQuestions = prevQuestions.filter((prevQuestion, i) => (i != currentIndex));
				newQuestions.splice(targetIndex, 0, draggingQuestion);
				return newQuestions;
			});
        }
    };

	const theme = createTheme({
		components: {
			MuiFormLabel: {
				styleOverrides: {
					asterisk: { color: "#d32f2f" },
				},
			},
		},
	
	})

  	const handleAddQuestion = () => {
		setQuestions([...questions, currentQuestion])
		setCurrentQuestion({
			type: "MCQ",
			statement: "",
			questionImages: [],
			options: [{
				id: 1,
				optionStatement: 'Option'
			}],
			correctAnswers: [],
			explanation: "",
			points: "1"
		})
		setShowAddQuestionForm(false);
  	}

	return (
		<div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={`${styles['modal-container']}`}>
                    <div className={`${styles['row']}`}>
                        <h1 className={`${styles['modal-heading']}`}>Create Quiz</h1>
                        <ClearIcon 
                            onClick={handleClose}
                            sx={{
                                fontSize: "2.5em",
                                cursor: "pointer"
                            }}
                        />
                    </div>
					<div className={`${styles['modal-input-container']}`}>
						<div className={`${styles['row']} ${styles['to-col']}`} style={{gap: "1em", alignItems: "flex-start"}}>
							<ThemeProvider theme={theme}>
								<TextField
									autoFocus={true}
									id="quiz-title"
									label="Quiz Title"
									onBlur={() => {
										if (quizTitle === '') {
											setIsValidTitle(false);
										}
									}}
									onChange={(e) => {setIsValidTitle(true); setQuizTitle(e.target.value);}}
									value={quizTitle}
									fullWidth
									required
									color={!isValidTitle ? 'error' : 'primary'}
									error={!isValidTitle}
									helperText={!isValidTitle ? '* Quiz title is required' : ''}
								/>
							</ThemeProvider>

							<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
								<ThemeProvider theme={theme}>	
									<TimePicker
										label="Time Limit"
										required
										id='quiz-time-limit'
										defaultValue={dayjs('2022-04-17T01:00')}
										value={timeLimit}
										views={['hours', 'minutes', 'seconds']}
										onChange={(newTimeLimit) => {
											setTimeLimit(newTimeLimit)
										}}
										ampm={false}
										color={!isValidTimeLimit ? 'error' : 'primary'}
										error={!isValidTimeLimit}
										helperText={!isValidTimeLimit ? 'Quiz title is required' : ''}
										minTime={dayjs('2022-04-17T00:00:01')}
										fullWidth
									/>
								</ThemeProvider>
							</LocalizationProvider>
						</div>

						<TextField
							fullWidth
							id="quiz-synopsis"
							label="Quiz Synopsis"
							onBlur={() => setQuizSynopsis(quizSynopsis)}
							onChange={(e) => setQuizSynopsis(e.target.value)}
							value={quizSynopsis}
							multiline
							rows={3}
						/>

						<div
							className={`${styles['row']}`}
						>
							<div>
								<h3 className={`${styles['h3']}`}>QUESTIONS</h3>
								{
									!isValidQuestions && 
									<p 
										className={styles['error-helper-text']}
									>
										* At least one question is required
									</p>
								}
							</div>
							<Fab aria-label="add" color={isValidQuestions ? "primary" : 'error'} onClick={() => {setIsValidQuestions(true); setShowAddQuestionForm(true);}}>
								<Tooltip title="Add Question">
									<AddIcon />
								</Tooltip>
							</Fab>
						</div>

						{
							showAddQuestionForm && 
							<div className={`${styles['create-question-form']}`}>
								<div className={`${styles['row']}`}>
									<h3 className={`${styles['h3']}`}>New Question</h3>
									<ClearIcon 
										onClick={(e)=>{
											e.stopPropagation()
											setCurrentQuestion({
												type: "MCQ",
												statement: "",
												questionImages: [],
												options: [{
													id: 1,
													optionStatement: 'Option'
												}],
												correctAnswers: [],
												explanation: "",
												points: "1"
											})
											setShowAddQuestionForm(false)
										}}
										sx={{
											fontSize: "2.5em",
											cursor: "pointer"
										}}
									/>
								</div>
								<NewQuestion 
									currentQuestion={currentQuestion}
									setCurrentQuestion={setCurrentQuestion} 
									addQuestion={handleAddQuestion}
									isNew={true}
								/>
							</div>
						}

						<div className={`${styles['questions-list']}`}>
							{questions.length === 0 ? (
								<div
									className={`${styles['row-center']}`} 
								>
									<p className={`${styles['default-text']}`}>No questions added</p>
								</div>
							) : (
								questions.map((question, i) => (editQuestionIndex === i) ? (
								<div className={`${styles['create-question-form']}`}>
									<div className={`${styles['row']}`}>
										<h3 className={`${styles['h3']}`}>Edit Question {i + 1}</h3>
										<ClearIcon 
											onClick={()=>{
												setEditQuestionIndex(-1)
											}}
											sx={{
												fontSize: "2.5em",
												cursor: "pointer"
											}}
										/>
									</div>
									<NewQuestion 
										currentQuestion={questions[editQuestionIndex]}
										setCurrentQuestion={(fn) => {
											setQuestions((prevQuestions) => (
												prevQuestions.map((prevQuestion, j) => {
													if (i === j) {
														return fn(prevQuestion);
													} else {
														return prevQuestion;
													}
												})
											))
										}} 
										addQuestion={() => setEditQuestionIndex(-1)}
										isNew={false}
									/>
								</div>
							) : (
								<div 
									draggable="true" 
									onDragStart={(e) => handleDragStart(e, question)}
									onDragEnd={handleDragEnd}
									onDragOver={handleDragOver}
									onDrop={(e) => handleDrop(e, question)} 
									key={i}
									onClick={() => {
										setEditQuestionIndex(i);
									}}
									className={`${styles['create-question-container']}`}
								>
									<div 
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											cursor: "grab"
										}}
									>
										<DragIndicatorIcon
											sx={{
												color: "rgba(0, 0, 0, 0.50)"
											}}
										/>
									</div>
									<div>
										<div className={`${styles['row']}`}>
											<h3 className={`${styles['h3']}`}>Question {i + 1}</h3>
											<div>
												<Tooltip title="Delete This Question">
													<DeleteIcon 
														onClick={
															(e)=>{
																e.preventDefault();
                            									e.stopPropagation();
																setQuestions(questions => (
																	questions.filter((question, j) => (
																		i !== j 
																	))
																))
															}
														} 
														sx={{
															fontSize: "2em"
														}}
													/>
												</Tooltip>
											</div>
										</div>	
										<div dangerouslySetInnerHTML={{__html: question.statement}}></div>
									</div>
								</div>
							)))}
						</div>
					</div>
					<div className={`${styles['row-center']} ${styles['create-quiz-btns']}`} style={{gap: '1em'}}>
                        <Button
                            className={`btn btn-primary ${styles['add-to-course-btn']}`}
                            onClick={() => handleSubmit(false)}
                            variant='contained'
                            size='large'
                        >
                            Create
                        </Button>

						<Button
                            className={`btn btn-primary ${styles['add-to-course-btn']}`}
                            onClick={() => handleSubmit(true)}
                            variant='contained'
                            size='large'
                        >
                            Create and Publish
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
	)
}

export default CreateQuiz