/* eslint-disable */
import React, { Fragment, useEffect } from 'react'
import {
    Card,
    CardContent,
    Box,
    Typography,
    Grid,
    Stack,
    Divider,
    Link,
    Fab,
    Tooltip,
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, NavLink } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import { History } from '@mui/icons-material'
import TopLoader from '../commons/TopLoader'
import {
    fetchSavedCoursesMeta,
    deleteCourse,
    fetchCanvasAccessData,
} from '../../redux'
import '../../css/Card.css'
import '../../css/CourseHome.css'
import VerticalDotsMenu from '../commons/VerticalDotsMenu'
import CourseCards from '../canvas/CoursesInfo'
import CanvasRequestAccess from '../canvas/CanvasRequestAccess'

const getRandomColor = () => {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i += 1) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}
function CoursesList() {
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const canvasAccessData = useSelector((state) => state.canvasAccessData)
    const savedCourseData = useSelector((state) => state.savedCourseData)
    console.log(userData)
    console.log(savedCourseData)
    const uid = userData && userData.user && userData.user.id
    // const creatorId = savedCourseData && savedCourseData
    // const isInstructor = userData && userData.user && userData.userFetchCompleted && userData.user.id == ;
    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            dispatch(fetchSavedCoursesMeta())
        }
    }, [dispatch, userData.userFetchCompleted, userData.user])

    useEffect(() => {
        window.scrollTo(0, 0)
        // console.log('This useEffect', userData)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            dispatch(fetchCanvasAccessData(userData.user.id))
        }
    }, [dispatch, userData.userFetchCompleted, userData.user])

    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }
    if (savedCourseData.loading) {
        return (
            <div className="container main-container mb-5">
                <TopLoader message={savedCourseData.loadingMessage} />
            </div>
        )
    }
    if (savedCourseData.error) {
        return (
            <div className="container main-container">
                <h4 className="alert alert-danger mt-4">
                    {savedCourseData.error}
                </h4>
            </div>
        )
    }
    if (savedCourseData.savedCoursesMeta.length === 0) {
        return (
            <>
                <div className="container main-container">
                    <h4 className="alert alert-light mt-4">
                        No courses added.
                    </h4>
                    <NavLink to="/grade-analyzer">
                        <Fab aria-label="add" color="primary">
                            <Tooltip title="Add Course">
                                <AddIcon />
                            </Tooltip>
                        </Fab>
                    </NavLink>
                </div>

                <Stack mt={10}>
                    <CourseCards />
                </Stack>
                {/* <div className="container course-top-wrapper">
                    <h1 className="container">Courses</h1>

                </div> */}
            </>
        )
    }
    const handleDeleteGradesClick = (courseId) => {
        if (
            // eslint-disable-next-line no-alert
            window.confirm(
                'Are you sure you want to delete this saved course? This action is irreversible.'
            )
        ) {
            dispatch(deleteCourse(courseId))
            // TODO: add another redux action to handle this in savedCourse
            window.location.reload()
        }
    }
    const courseCard = savedCourseData.savedCoursesMeta.map(
        (savedCourseMeta) => {
            const { id, name, createdBy, lastUpdatedOn } = savedCourseMeta

            return (
                <Grid item xs={12} sm={6} md={4} key={id}>
                    <Card>
                        <Box
                            sx={{
                                bgcolor: getRandomColor(),
                                color: 'white',
                                minHeight: 200,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {name}
                        </Box>
                        <CardContent>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                >
                                    <Link
                                        href={`/course/home/${encodeURI(id)}`}
                                        rel="noopener noreferrer"
                                    >
                                        {name}
                                    </Link>
                                </Typography>
                                {createdBy === uid ? (
                                    <VerticalDotsMenu
                                        onClick={() =>
                                            handleDeleteGradesClick(id)
                                        }
                                    />
                                ) : (
                                    <div></div>
                                )}
                            </div>
                            <div className="row ml-1">
                                <History style={{ alignSelf: 'center' }} />
                                <p className="card-text m-2">
                                    {new Date(lastUpdatedOn).toLocaleString()}
                                </p>
                            </div>
                            <a
                                className="btn btn-primary"
                                href={`/course/home/${encodeURI(id)}`}
                            >
                                Open
                            </a>
                        </CardContent>
                    </Card>
                </Grid>
            )
        }
    )
    return (
        <>
            <div className="container course-top-wrapper">
                <h1 className="container">Courses</h1>

                <NavLink to="/grade-analyzer">
                    <Fab aria-label="add" color="primary">
                        <Tooltip title="Add Course">
                            <AddIcon />
                        </Tooltip>
                    </Fab>
                </NavLink>
            </div>
            <Stack spacing={2} marginBottom={50}>
                {/* <Typography variant="h4" align="center">
                    Saved Courses
                </Typography> */}
                <Divider />
                <Grid container spacing={2} paddingLeft={10} paddingRight={15}>
                    {courseCard}
                </Grid>

                {canvasAccessData?.canvasAccessData?._doc?.hasCanvasAccess &&
                userData.user.canvasAccess ? (
                    <Stack>
                        <CourseCards />
                    </Stack>
                ) : userData.user.canvasAccess ? (
                    <Stack>
                        <CanvasRequestAccess />
                    </Stack>
                ) : null}

                {/* {userData.user.canvasAccess ? (
                    <Stack>
                        <CanvasRequestAccess />
                    </Stack>
                ) : (
                    <Stack>
                        <CourseCards />
                    </Stack>
                )} */}
            </Stack>
        </>
    )
}

export default CoursesList
