/* eslint-disable */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import EditIcon from '@mui/icons-material/Edit'
import EditOffIcon from '@mui/icons-material/EditOff'
import { useDispatch, useSelector } from 'react-redux'
import { TextField, Typography } from '@mui/material'
import axios from 'axios'
import {
    courseMeetingDetailsUpdate,
    updateCourseFields,
} from '../../../../redux'
import { addAnchorTagsForLinksInText } from '../../../../utils'

export default function MeetingDetails({ courseId, courseMeetingDetails }) {
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const errorData = useSelector((state) => state.errorData)
    const [enableEdit, setEnableEdit] = useState(false)
    const [searchedMeetingDetails, setSearchedMeetingDetails] = useState('')
    const [role, setRole] = useState(null)

    useEffect(() => {
        async function fetchData() {
            try {
                const coursedetails = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/course/${courseId}`
                )
                const { meetingDetails } = coursedetails.data
                const searchedMeetingDetails = meetingDetails || '' // Set to empty string if meetingDetails is falsy
                console.log(searchedMeetingDetails)
                setSearchedMeetingDetails(searchedMeetingDetails)
            } catch (err) {
                console.error(err)
                throw new Error(
                    'Error searching for meeting details in the syllabus.'
                )
            }
        }

        fetchData()
    }, [courseId])

    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            setRole(userData.user.role)
            if (userData.user.firstName === 'Admin') {
                setRole('Admin')
            }
        }
    }, [dispatch, userData.userFetchCompleted, userData.user])

    const handleEditButtonClick = () => {
        if (!enableEdit) {
            // Save changes if currently in edit mode
            dispatch(
                updateCourseFields(courseId, {
                    meetingDetails: searchedMeetingDetails,
                })
            )
        }
        setEnableEdit(!enableEdit)
    }

    return (
        <div
            style={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '70vh',
            }}
        >
            {enableEdit ? (
                <TextField
                    error={!!errorData.meetingDetails}
                    fullWidth
                    helperText={`${searchedMeetingDetails.length}/512 characters`}
                    id="description-edit"
                    inputProps={{
                        style: {
                            padding: 0,
                            minHeight: '23.5%',
                        },
                    }}
                    multiline
                    onBlur={handleEditButtonClick}
                    onChange={(e) => setSearchedMeetingDetails(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    rows={4}
                    value={searchedMeetingDetails}
                    variant="filled"
                />
            ) : (
                <Typography
                    id="description"
                    onClick={(e) => e.stopPropagation()}
                    style={{ whiteSpace: 'pre-line' }}
                    sx={{
                        flexGrow: 20,
                        display: '-webkit-box',
                        overflow: 'scroll',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 4,
                        background: 'rgb(247, 247, 247)',
                        minHeight: '7.8em',
                        padding: '25px 12px 8px',
                        border: errorData.description
                            ? '1px red solid'
                            : 'none',
                    }}
                    variant="body1"
                >
                    {addAnchorTagsForLinksInText(searchedMeetingDetails)}
                </Typography>
            )}
            {role === 'Instructor' && (
                <button
                    className="btn btn-primary m-2"
                    onClick={handleEditButtonClick}
                    style={{ width: 'fit-content' }}
                    type="button"
                >
                    {enableEdit ? (
                        <>
                            <EditOffIcon fontSize="small" />{' '}
                        </>
                    ) : (
                        <>
                            <EditIcon fontSize="small" /> Edit
                        </>
                    )}
                </button>
            )}
        </div>
    )
}

MeetingDetails.propTypes = {
    courseId: PropTypes.string.isRequired,
    courseMeetingDetails: PropTypes.string.isRequired,
}
