/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import { useTimer } from "react-timer-hook";

const Timer = ({timeLimit, className, isRunning, handleOnQuizEnd}) => {
	const startDateTime = new Date();
	const endDateTime = new Date();
	const timeLimitArray = timeLimit?.split(':');
	const hoursLimit = timeLimitArray[0];
	const minutesLimit = timeLimitArray[1];
	const secondsLimit = timeLimitArray[2];
	endDateTime.setHours(startDateTime.getHours() + parseInt(hoursLimit));
	endDateTime.setMinutes(startDateTime.getMinutes() + parseInt(minutesLimit));
	endDateTime.setSeconds(startDateTime.getSeconds() + parseInt(secondsLimit));
	
	const {
		totalSeconds,
		seconds,
		minutes,
		hours,
		start,
		pause,
		resume,
		restart,
	  } = useTimer({
		expiryTimestamp: endDateTime,
		autoStart: false,
		onExpire: handleOnQuizEnd,
	  });

	useEffect(()=>{
		if (isRunning) {
			start();
		} else {
			pause();
		}
	}, [isRunning]);

  	return (
		<h4>
    		{hours > 9 ? hours : '0' + hours}:{minutes > 9 ? minutes : '0' + minutes}:{seconds > 9 ? seconds : '0' + seconds}
		</h4>
  	)
}

export default Timer